import {
  AnimatePresence,
  motion,
  useTransform,
  useViewportScroll,
} from 'framer-motion';
import React from 'react';
import { useNewsletter } from '../../hooks/use-newsletter';
import WaveLinen from '../../images/wave-linen.png';
import WaveWhite from '../../images/wave-white-pattern.png';
import BreadIcon from '../atoms/BreadIcon';
import Button from '../atoms/Button';
import CoffeeBeanIcon from '../atoms/CoffeeBeanIcon';
import ErrorIcon from '../atoms/ErrorIcon';

interface NewsletterSectionProps {
  isLinen?: boolean;
}

const NewsletterSection = ({ isLinen }: NewsletterSectionProps) => {
  const { scrollY } = useViewportScroll();
  const x = useTransform(scrollY, [0, 10000], [0, -1000]);
  const { email, setEmail, success, error, validate, handleSubmit } =
    useNewsletter();

  return (
    <section className="relative">
      {isLinen && (
        <div className="absolute top-0 left-0 right-0 bottom-20 bg-linen -z-1" />
      )}
      <div className="absolute bottom-0 left-0 right-0 h-20 bg-navy-blue -z-1" />
      <div className="container">
        <div
          className={`relative flex flex-col px-5 py-10 lg:px-24 lg:py-16 lg:flex-row ${
            isLinen ? 'bg-white' : 'bg-linen'
          }`}
        >
          <div className="absolute left-0 right-0 z-10 h-8 -mt-2.5 overflow-hidden top-full">
            <motion.div
              className="w-[10000px] h-full bg-repeat-x"
              style={{
                backgroundImage: `url(${isLinen ? WaveWhite : WaveLinen})`,
                backgroundSize: '1.5% 100%',
                x: x,
              }}
            />
          </div>
          <CoffeeBeanIcon className="absolute top-0 left-0 z-10 w-auto h-24 -mt-16 -ml-2 lg:-mt-12 lg:-ml-4" />
          <h2 className="mr-12 text-2xl font-bold leading-9 uppercase lg:max-w-xs font-heading text-navy-blue">
            Subscribe to our mailing list to receive news and special offers
          </h2>
          <div className="flex-grow lg:flex mt-9 lg:mt-0 lg:pl-12 lg:border-l border-navy-blue border-opacity-20">
            <AnimatePresence exitBeforeEnter>
              {success ? (
                <motion.div
                  key="success"
                  className="flex items-center space-x-6 text-2xl font-bold uppercase font-heading text-navy-blue lg:pl-9"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <BreadIcon />
                  <div>THANKS FOR SIGNING UP!</div>
                </motion.div>
              ) : (
                <motion.form
                  key="form"
                  action=""
                  onSubmit={handleSubmit}
                  className="w-full"
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <label
                    htmlFor="email"
                    className={`u-label ${error && 'u-label--error'}`}
                  >
                    Your email address
                  </label>
                  <div className="items-center w-full lg:space-x-4 lg:flex lg:flex-row lg:w-auto">
                    <div className="relative inline-flex flex-grow w-full lg:w-auto lg:max-w-[520px]">
                      <input
                        className={`u-input ${
                          error && 'u-input--error'
                        } flex-grow`}
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Type your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={() => validate()}
                      />
                      <AnimatePresence>
                        {error && (
                          <ErrorIcon className="absolute right-0 mr-4 -mt-3 pointer-events-none top-1/2" />
                        )}
                      </AnimatePresence>
                    </div>
                    <AnimatePresence>
                      {error && (
                        <div className="lg:hidden">
                          <motion.div
                            className="text-red-accessibility pt-0.5"
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                          >
                            {error}
                          </motion.div>
                        </div>
                      )}
                    </AnimatePresence>
                    <Button
                      type="submit"
                      className="w-full mt-4 lg:w-auto lg:mt-0"
                    >
                      Subscribe
                    </Button>
                  </div>
                  <AnimatePresence>
                    {error && (
                      <motion.div
                        className="text-red-accessibility pt-0.5"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                      >
                        <div className="hidden lg:block">{error}</div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <p className="mt-4 text-navy-blue">
                    By signing up, you agree to The Salty's{' '}
                    <a
                      href="https://lunchbox.io/privacy"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="underline"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </motion.form>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsletterSection;
