import React from 'react';

const BurgerClose = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#000"
      d="M3.515 4.93L19.07 20.486l1.414-1.414L4.93 3.516 3.515 4.93z"
    ></path>
    <path
      fill="#000"
      d="M19.07 3.515L3.516 19.072l1.414 1.414L20.485 4.93l-1.414-1.415z"
    ></path>
  </svg>
);

export default BurgerClose;
