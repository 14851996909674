import React from 'react';

const CoffeeBeanIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="95"
    fill="none"
    viewBox="0 0 65 95"
    {...props}
  >
    <g clipPath="url(#clip0_151_12658)">
      <path
        fill="#E7C6C7"
        d="M58.072 31.048c.036-3.957-.06-7.854.459-11.79a30.531 30.531 0 013.427-10.555c1.099-2.089 2.334-4.165 3.042-6.37-2.193-.089-6.84 2.168-8.718 3.824-7.822 6.887-7.983 14.171-11.406 23.652-3.392 9.404-7.588 20.918-15.792 27.26-.338.264-.696.517-1.062.765.056-.128.109-.232.14-.292 2.693-4.811 4.829-10.107 6.784-15.234 1.77-4.61 3.28-9.42 3.927-14.34C38.068 18.265 33.816 9.38 31.41.017c-1.767 1.543-2.857 4.008-3.662 6.35-.446 10.523-2.578 21.002-6.887 30.65-1.304 2.923-2.696 5.841-3.899 8.788-.084-.329-.169-.654-.241-.986-1.799-8.194-1.288-16.777-3.143-25.023-.402-1.788-2.381-4.955-3.942-5.18.157 3.981-1.276 8.6-1.96 11.81a37.875 37.875 0 01-2.96 8.247C3.64 36.94 2.41 39.169 1.25 41.422a25.31 25.31 0 00-.555 7.982c.982 9.104 5.822 16.837 13.68 21.611a3.883 3.883 0 003.439.433c3.69 1.66 7.644 2.277 12.472 1.66 8.413-1.07 15.26-5.272 19.887-12.371 3.348-5.132 5.492-11.225 7.02-17.29.218-4.15.842-8.254.878-12.4z"
      ></path>
      <path
        fill="#434867"
        d="M39.641 63.667l-2.1-1.76c-.768-.645-1.235-.432-2.237-.352.588 1.298 1.26 2.59 1.779 3.916.358.914.382 1.568-.322 2.297-.805.838-1.81 1.552-2.66 2.365a14.856 14.856 0 01-3.158 2.281c-2.124 1.167-4.627 3.071-7.214 2.682-1.951-.296-.772-2.405-1.488-3.576-.21-.34-1.372-1.098-1.754-.942-.194.077-.572 1.251-.668 1.46a7.705 7.705 0 00-.668 1.888c-.113.645.221 1.355.133 1.928-.077.497-.962.706-1.525.878-1.401.392-2.856.558-4.31.493-2.872-.032-5.583 1.178-8.476.802-1.714-.237-4.426-1.05-4.892-2.97-.282-1.12.217-2.643.523-3.717a19.982 19.982 0 011.79-4.009 42.937 42.937 0 014.659-6.75c1.61-1.94 3.351-4.01 5.295-5.637.981-.802 2.06-1.247 3.154-1.86 1.207-.677 2.136-1.708 3.283-2.465 2.156-1.423 4.755-2.129 7.056-3.291 1.079-.546 2.266-.862 3.38-1.352a41.284 41.284 0 014.176-1.683c2.784-.89 5.927-2.005 8.876-2.065 2.949-.06 5.773 1.435 8.448 2.406 1.063.4 2.052.898 2.652 1.896.6.998 1.488 2.124 1.83 3.207.805 2.565-1.323 4.008-3.524 4.518-1.364.312-2.756.605-4 1.27-1.387.742-2.502 1.985-3.62 3.071-.921.89-2.229 1.88-2.716 3.083-.41.942-.495 1.748-1.702 1.988z"
      ></path>
      <path
        fill="#434867"
        d="M1.42 82.171c1.61-.349 3.195-.782 4.86-.894a54.107 54.107 0 015.468.06c3.44.113 6.84-.681 10.227-1.082 1.529-.176 3.01-.285 4.426-.918.696-.313 1.315-.762 2.011-1.054.338-.14 1.787-.802 2.145-.682.523.165 1.022 1.78 1.36 2.209a2.675 2.675 0 002.63 1.006c-.358-.948-.649-1.92-.868-2.91a15.325 15.325 0 01-.15-1.604c-.051-.854 0-.95.697-1.483a24.602 24.602 0 004.365-4.654c1.32-1.74 2.575-3.516 3.782-5.328 1.348-2.004 2.579-3.5 4.623-4.834a40.057 40.057 0 015.673-3.167c1.955-.862 4.023-1.776 6.035-.618 1.822 1.047 2.47 3.123 2.454 5.156 0 1.07-.403 2.004-.644 3.014-.274 1.115-.362 2.233-.857 3.291-.982 2.093-2.213 4.126-3.383 6.118-1.171 1.992-2.861 3.48-4.547 5.03-.825.758-1.702 1.484-2.486 2.286-.282.293-.507.746-.805.998a2.72 2.72 0 01-.913.433c-1.284.525-1.77 2.028-2.97 2.662-.748.4-1.23.42-1.878 1.026-.648.605-1.28 1.363-2.112 1.804a97.737 97.737 0 01-5.899 2.886c-1.983.882-4.1 1.295-6.16 1.98a33.868 33.868 0 01-6.79 1.696c-2.543.297-5.058.658-7.56.1-.62-.136-1.082.097-1.65.077-.567-.02-1.303-.377-1.838-.533a35.768 35.768 0 01-3.392-1.13C5.226 92.276 3.11 91.07 2.14 88.99c-.97-2.08-.631-4.61-.72-6.819z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_151_12658">
        <path fill="#fff" d="M0 0H65V95H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default CoffeeBeanIcon;
