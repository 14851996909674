import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import MenuHeroSection from '../components/organisms/MenuHeroSection';
import NewsletterSection from '../components/organisms/NewsletterSection';
import { PromoPopup } from '../components/organisms/PromoPopup';

const Menu = ({
  data: {
    page,
    cities,
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo post={page} />

        <MenuHeroSection
          name={page.title}
          slug={page.slug}
          cities={cities.nodes}
        />

        <div className="h-40" />

        <NewsletterSection />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query MenuPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
    }
    cities: allWpCity {
      nodes {
        title
        slug
        city {
          showMenu
        }
      }
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default Menu;
