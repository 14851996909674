import { motion } from 'framer-motion';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import React from 'react';

const Image = (props: GatsbyImageProps) => {
  return (
    <motion.div
      className="flex"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
    >
      <GatsbyImage {...props} />
    </motion.div>
  );
};

export default Image;
