import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';
import { getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { useLockBodyScroll } from 'react-use';
import CloseButtonBg from '../../images/close-btn-bg.svg';
import BurgerClose from '../atoms/BurgerClose';
import Image from '../atoms/Image';

export const PromoPopup = ({ popupEnabled, image, url }) => {
  const [popupDismissed, setPopupDismissed] = useState(
    (typeof window !== 'undefined' &&
      window.sessionStorage.getItem('popupDismissed')) ||
      false
  );

  const close = () => {
    setPopupDismissed(true);
    window.sessionStorage.setItem('popupDismissed', 'true');
  };

  const navigateTo = (url: string) => {
    close();
    navigate(url);
  };

  useLockBodyScroll(popupEnabled && !popupDismissed);

  return (
    <AnimatePresence initial={false}>
      {popupEnabled && !popupDismissed && (
        <motion.div
          className="fixed inset-0 z-[9999999] bg-navy-blue/50 flex items-center justify-center px-6"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="relative w-full max-w-xl overflow-hidden rounded">
            {url ? (
              <button type="button" onClick={() => navigateTo(url)}>
                <Image
                  image={getImage(image.localFile)!}
                  alt={image.altText}
                  className="w-full h-auto"
                />
              </button>
            ) : (
              <Image
                image={getImage(image.localFile)!}
                alt={image.altText}
                className="w-full h-auto"
              />
            )}
            <button
              type="button"
              className="absolute flex items-center justify-center w-12 h-12 bg-center bg-no-repeat top-6 right-6 bg-container group"
              style={{ backgroundImage: `url(${CloseButtonBg})` }}
              onClick={close}
            >
              <BurgerClose className="w-6 h-6 transition-all origin-center group-hover:scale-110" />
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
