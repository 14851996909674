import axios from 'axios';
import { useState } from 'react';
import { object, string } from 'yup';

const schema = object({
  email: string()
    .required('Please provide your email address')
    .email('Email address must be valid'),
});

export const useNewsletter = (locationName = '') => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState(false);

  const validate = async () => {
    try {
      await schema.validate({ email });
      setError(null);
    } catch (e) {
      setError(e.message);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await validate();

    if (error) {
      return;
    }

    setError(null);

    try {
      await axios.post(
        `https://track.customer.io/api/v1/forms/${process.env.GATSBY_CUSTOMER_IO_NEWSLETTER_FORM_ID}/submit`,
        {
          data: { email, location_coming_soon: locationName },
        },
        {
          headers: {
            Authorization: `Basic ${btoa(
              `${process.env.GATSBY_CUSTOMER_IO_SITE_ID}:${process.env.GATSBY_CUSTOMER_IO_API_KEY}`
            )}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setSuccess(true);
    } catch (e) {
      setError('Newsletter sign up submission has failed.');
    }
  };

  return { email, setEmail, success, error, validate, handleSubmit };
};
